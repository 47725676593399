import React from "react";

import { Switch, BrowserRouter, Route } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import Home from "./screens/Home";
import Place from "./screens/Place";
import SearchResults from "./screens/SearchResults";
import Guide from "./screens/Guide";
import NotFoundPage from "./screens/NotFoundPage";

import Header from "./components/Header/Header";
import Footer from "./components/Footer";

import { ScreenSizeContextProvider } from "./contexts/ScreenSizeContext";
import { SearchContextProvider } from "./contexts/SearchContext";
import { LanguageContextProvider } from "./contexts/LanguageContext";
import { APIContextProvider } from "./contexts/APIContext";
import { PageView, initGA } from "./tracking";

const useStyles = makeStyles((theme) => ({
  appSite: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
  siteContent: {
    flex: "1 0 auto",
    padding: "1.5em 0 2em 0 0",
    width: "100%",
  },
}));
function App() {
  const classes = useStyles();
  const trackingId = "UA-160854959-1";
  initGA(trackingId);
  PageView();

  return (
    <div>
      <ScreenSizeContextProvider>
        <LanguageContextProvider>
          <APIContextProvider>
            <SearchContextProvider>
              <BrowserRouter>
                <div className={classes.appSite}>
                  <div className={classes.siteContent}>
                    <Header />
                    <Switch>
                      <Route path="/" exact component={Home} />
                      <Route path="/search" exact component={SearchResults} />
                      <Route path="/id/:id" component={Place} />
                      <Route path="/guide" exact component={Guide} />
                      <Route component={NotFoundPage} />
                    </Switch>
                  </div>
                  <Footer />
                </div>
              </BrowserRouter>
            </SearchContextProvider>
          </APIContextProvider>
        </LanguageContextProvider>
      </ScreenSizeContextProvider>
    </div>
  );
}

export default App;
