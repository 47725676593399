import React, { useContext, useEffect } from "react";
import { COLORS } from "../helpers/Colors";
import { makeStyles } from "@material-ui/core/styles";

import search from "../assets/icons/search.svg";
import ResultCard from "../components/SearchResults/ResultCard";
import { Grid, Input, Button } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import SearchResultsSideBar from "../components/SearchResults/SearchResultsSideBar";
import { SearchContext } from "../contexts/SearchContext";
import { ScreenSizeContext } from "../contexts/ScreenSizeContext";

import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import IconButton from "@material-ui/core/IconButton";
import LoadingPage from "../components/LoadingPage";
import { arabic, english } from "../helpers/Translations";
import { LanguageContext } from "../contexts/LanguageContext";
import { APIContext } from "../contexts/APIContext";

const useStyles = makeStyles((theme) => ({
  body: {
    direction: "rtl",
    margin: "20px",
  },
  searchWordIcon: {
    display: "inline-flex",
    alignItems: "center",
    marginRight: "20px",
  },
  resultsWord: {
    fontFamily: "Frutiger-Normal",
    fontWeight: "900",
    fontSize: "20px",
    margin: "5px",
    marginTop: "0px",
    marginBottom: "0px",
  },
  searchIcon: {
    width: "35px",
    height: "40px",
    marginLeft: "6px",
  },
  searchInputField: {
    borderRadius: "50px",
    border: "2px solid",
    borderColor: COLORS.shamseyaBlue,
    width: "100%",
    height: "50px",
    fontSize: "20px",
    paddingRight: "20px",
    paddingLeft: "20px",
    maxWidth: "1000px",
    fontFamily: "Frutiger-Normal",
  },
  searchButton: {
    backgroundColor: COLORS.shamseyaBlue,
    textTransform: "none",
    color: COLORS.white,
    fontSize: "16px",
    fontFamily: "Frutiger-Normal",
    borderRadius: "50px",
    width: "110px",
    height: "50px",
  },
  label: {
    color: COLORS.shamseyaBlue,
    fontFamily: "Frutiger-Normal",
    fontSize: "16px",
    marginRight: "6px",
  },
  gps: {
    width: "30px",
    height: "30px",
    color: COLORS.shamseyaBlue,
    marginRight: "15px",
  },
  root: {
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));
const SearchResults = () => {
  const classes = useStyles();
  const { currLanguage } = useContext(LanguageContext);
  const { API_URL } = useContext(APIContext);

  let translations = currLanguage === "ar" ? arabic : english;

  const {
    searchInput,
    setSearchInput,
    comboFiltersPicked,
    handleSearch,
    currPage,
    setCurrPage,
    pagesCount,
    searchResults,
    loading,
    error,
  } = useContext(SearchContext);

  const { matchesMD } = useContext(ScreenSizeContext);

  useEffect(() => {
    handleSearch(currPage);
  }, [API_URL]);

  useEffect(() => {
    setCurrPage(1);
  }, [searchInput, comboFiltersPicked]);

  const handleChangePage = (direction) => {
    // left
    if (direction === 1 && currPage < pagesCount) {
      setCurrPage(currPage + 1);
      handleSearch(currPage + 1);
      // right
    } else if (direction === -1 && currPage > 1) {
      setCurrPage(currPage - 1);
      handleSearch(currPage - 1);
    }
  };
  const Alert = (props) => {
    return (
      <MuiAlert
        style={{
          direction: currLanguage === "ar" ? "rtl" : "ltr",
          width: "80%",
          background:
            props.severity === "error" ? COLORS.failRed : COLORS.shamseyaBlue,
        }}
        elevation={6}
        variant="filled"
        {...props}
      />
    );
  };

  return (
    <div className={classes.body}>
      <Grid
        container
        alignItems="center"
        style={{ direction: currLanguage === "ar" ? "rtl" : "ltr" }}
      >
        <Grid
          item
          xs={12}
          s={12}
          md={3}
          lg={3}
          xl={3}
          container
          alignItems="center"
          style={{ direction: currLanguage === "ar" ? "rtl" : "ltr" }}
        >
          <div className={classes.searchWordIcon}>
            <img
              alt={translations.statics.searchResults}
              src={search}
              className={classes.searchIcon}
            />
            <p className={classes.resultsWord}>
              {translations.statics.searchResults}
            </p>
          </div>
        </Grid>
        <Grid item xs={12} s={7} md={6} lg={5} xl={5}>
          <Input
            className={classes.searchInputField}
            disableUnderline={true}
            placeholder={translations.statics.search}
            type={"text"}
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            style={{ direction: currLanguage === "ar" ? "rtl" : "ltr" }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          s={12}
          md={2}
          lg={3}
          xl={1}
          container
          justify="center"
        >
          <Button
            variant="contained"
            className={classes.searchButton}
            style={{ marginTop: matchesMD ? "0px" : "10px" }}
            onClick={() => handleSearch(currPage)}
          >
            {translations.statics.search}
          </Button>
        </Grid>
      </Grid>

      <Grid
        container
        justify="space-around"
        alignItems="flex-start"
        style={{
          marginTop: "40px",
          direction: currLanguage === "ar" ? "rtl" : "ltr",
        }}
      >
        <Grid
          item
          xs={12}
          s={12}
          md={3}
          l={3}
          xl={3}
          container
          justify={matchesMD ? "flex-start" : "center"}
          alignItems="flex-start"
        >
          <SearchResultsSideBar handleSearch={handleSearch} />
        </Grid>

        {error.isError ? (
          <Grid
            item
            xs={12}
            s={12}
            md={9}
            lg={9}
            xl={9}
            className={classes.root}
          >
            <Alert severity="error">Network Error</Alert>
          </Grid>
        ) : loading ? (
          <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
            <LoadingPage followScreenSize={true} maxWidth="1000px" />
          </Grid>
        ) : searchResults.length > 0 ? (
          <Grid
            item
            xs={12}
            s={12}
            md={9}
            lg={9}
            xl={9}
            container
            justify="center"
            direction="column"
            alignItems="flex-start"
          >
            {searchResults.map((searchResult, index) => (
              <ResultCard
                key={index}
                id={searchResult.id}
                index={index + (currPage - 1) * 12 + 1}
                name={searchResult.acf[`place_name_${currLanguage}`]}
                address={searchResult.acf[`address_${currLanguage}`]}
                gov={searchResult.acf[`gov_${currLanguage}`]}
                type={searchResult.acf[`type_select_tag_${currLanguage}`]}
                percentage={searchResult.acf.score}
                image={searchResult.acf.image.url}
                clean={searchResult.acf.clean}
                safe={searchResult.acf.safe}
                score={searchResult.acf.score}
                outdoor={searchResult.acf.outdoor}
                drivethrough={searchResult.acf.drivethrough}
                contactless={searchResult.acf.contactless}
              />
            ))}

            <Grid
              container
              direction="row"
              alignItems="center"
              justify="flex-end"
              style={{ maxWidth: "1000px" }}
            >
              <IconButton
                aria-label="delete"
                className={classes.margin}
                size="small"
                disabled={currPage === 1}
                onClick={() => handleChangePage(-1)}
              >
                {currLanguage === "ar" ? (
                  <ArrowRight
                    style={{
                      width: "60px",
                      height: "60px",
                      color:
                        currPage === 1 ? COLORS.disabledGray : COLORS.black,
                    }}
                  />
                ) : (
                  <ArrowLeft
                    style={{
                      width: "60px",
                      height: "60px",
                      color:
                        currPage === 1 ? COLORS.disabledGray : COLORS.black,
                    }}
                  />
                )}
              </IconButton>
              <IconButton
                aria-label="delete"
                className={classes.margin}
                size="small"
                disabled={currPage === pagesCount}
                onClick={() => handleChangePage(1)}
              >
                {currLanguage === "ar" ? (
                  <ArrowLeft
                    style={{
                      width: "60px",
                      height: "60px",
                      color:
                        currPage === pagesCount
                          ? COLORS.disabledGray
                          : COLORS.black,
                    }}
                  />
                ) : (
                  <ArrowRight
                    style={{
                      width: "60px",
                      height: "60px",
                      color:
                        currPage === pagesCount
                          ? COLORS.disabledGray
                          : COLORS.black,
                    }}
                  />
                )}
              </IconButton>
            </Grid>
          </Grid>
        ) : (
          <p style={{ textAlign: "right", fontSize: "18px", width: "70%" }}>
            {translations.statics.noSearchResults}
          </p>
        )}
      </Grid>
    </div>
  );
};
export default SearchResults;
