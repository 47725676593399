import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Copyright from "@material-ui/icons/Copyright";

import { COLORS } from "../helpers/Colors";
import SocialMedia from "./Header/SocialMedia";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  body: {
    backgroundColor: COLORS.footerBg,
    minHeight: "80px",
    textAlign: "center",
    padding: "5px",
  },
  copyRight: {
    marginTop: "5px",
    marginLeft: "3px",
    color: COLORS.black,
    fontSize: "14px",
    width: "100%",
    display: "inline",
  },
  copyRightIcon: {
    color: COLORS.shamseyaBlue,
    display: "inline-flex",
    marginBottom: "-6px",
  },
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <div className={classes.body}>
      <Copyright className={classes.copyRightIcon} />
      <div className={classes.copyRight}>
        2021 Developed and managed by{" "}
      </div>{" "}
      <div className={classes.copyRight} style={{ fontWeight: "900" }}>
        Shamseya for Innovative Community Health Care Solutions
      </div>
      <Grid item xs={12}>
        <SocialMedia />
      </Grid>
    </div>
  );
};

export default Footer;
