import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { LanguageContext } from "./LanguageContext";

export const APIContext = createContext();

const ENGLISHAPI = "https://new.api.salametna.com/en/wp-json/wp/v2/";

const APIContextProvider = (props) => {
  const { currLanguage } = useContext(LanguageContext);
  const [API_URL, setAPI] = useState(
    "https://new.api.salametna.com/wp-json/wp/v2/"
  );
  let salametna = axios.create({
    baseURL: API_URL,
  });

  useEffect(() => {
    if (currLanguage === "ar") {
      setAPI("https://new.api.salametna.com/wp-json/wp/v2/");

      return;
    }
    setAPI("https://new.api.salametna.com/" + currLanguage + "/wp-json/wp/v2/");
  }, [currLanguage]);

  useEffect(() => {
    salametna = axios.create({
      baseURL: API_URL,
    });
  }, [API_URL]);

  const retrieveFilters = () => {
    let categoriesPath = "categories?per_page=100";
    let tagsPath = "tags?per_page=100";
    let responseObj = {};
    return new Promise((resolve, reject) => {
      salametna
        .get(categoriesPath)
        .then((response) => {
          salametna.get(tagsPath).then((response2) => {
            responseObj.categories = response.data;
            responseObj.tags = response2.data;
            resolve(responseObj);
          });
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getFilters = (setFilters, setGovAreaMappings, setError) => {
    const parents = [];
    const children = [];
    const finalFilters = [];
    let govAreaMappings = {};
    let allGovs = [];
    retrieveFilters().then((responseObj) => {
      let categoriesResponse = JSON.parse(
        JSON.stringify(responseObj.categories)
      );
      let tagsResponse = JSON.parse(JSON.stringify(responseObj.tags));
      let tagsObj = {
        title: "نوع المكان",
        id: "type_of_place",
        options: [],
      };
      tagsResponse.map((tag) => {
        let tagRecord = {
          id: tag.id,
          name: tag.name,
          parent: "type_of_place",
        };
        tagsObj.options.push(tagRecord);
      });

      categoriesResponse.map((category) => {
        let record = {
          id: category.id,
          name: category.name,
          parent: category.parent,
        };
        if ((category.parent === 0) & (category.name !== "Uncategorized")) {
          parents.push(record);
          let parentRecord = {
            title: category.name,
            id: category.id,
            options: [],
          };
          finalFilters.push(parentRecord);
        } else {
          record.parent = category.parent;
          children.push(record);
          // get all govs as gov type id is 2
          if (record.parent == "2") {
            allGovs.push(record.id);
          }
        }
      });
      children.map((child) => {
        finalFilters.map((parent, i) => {
          if (parent.id === child.parent) {
            finalFilters[i].options.push(child);
          }
        });

        if (allGovs.includes(child.parent)) {
          if (govAreaMappings[child.parent]) {
            govAreaMappings[child.parent].push(child);
          } else {
            govAreaMappings[child.parent] = [child];
          }
        }
      });
      // Default - Cairo - Alex - Giza
      finalFilters[2].options = govAreaMappings[6];
      finalFilters[2].options.push(...govAreaMappings[8]);
      if (govAreaMappings[102]) {
        finalFilters[2].options.push(...govAreaMappings[102]);
      }

      let cairoAreas = govAreaMappings[6].filter((area) => area.parent == 6);
      govAreaMappings[6] = cairoAreas;
      setGovAreaMappings(govAreaMappings);
      finalFilters[0] = tagsObj;
      setFilters(finalFilters);
    });
  };

  const getRecentReviews = (page) => {
    const path = `${ENGLISHAPI}posts?per_page=6&page=${page}`;
    return new Promise((resolve, reject) => {
      salametna
        .get(path)
        .then((response) => {
          let newResponse = {
            totalPages: response.headers["x-wp-totalpages"],
            places: [...response.data],
          };
          resolve(newResponse);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getPlaceInfo = (id) => {
    const path = `${ENGLISHAPI}posts/${id}`;

    return new Promise((resolve, reject) => {
      salametna
        .get(path)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getGuidePage = () => {
    const path = "pages/3392";
    return new Promise((resolve, reject) => {
      salametna
        .get(path)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getCategoriesMappingPage = () => {
    const path = "pages/4620";
    return new Promise((resolve, reject) => {
      salametna
        .get(path)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const searchHandler = (filters, tags, searchInput, currPage) => {
    let path = `${ENGLISHAPI}posts/?`;

    if (!(!searchInput || 0 === searchInput.length)) {
      path += "search=" + searchInput + "&";
    }
    if (filters && filters.length > 0) {
      filters.map((filter, i) => {
        if (i === 0) {
          path += "categories=" + filter + ",";
        } else {
          path += filter + ",";
        }
      });
    }
    if (tags && tags.length > 0) {
      tags.map((tag, i) => {
        if (i == 0) {
          path += "&tags=" + tag + ",";
        } else {
          path += tag + ",";
        }
      });
    }

    path += "&per_page=10&page=" + currPage;
    return new Promise((resolve, reject) => {
      salametna
        .get(path)
        .then((response) => {
          let newResponse = {
            totalPages: response.headers["x-wp-totalpages"],
            places: [...response.data],
          };
          resolve(newResponse);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  return (
    <APIContext.Provider
      value={{
        API_URL,
        salametna,
        getFilters,
        getRecentReviews,
        getPlaceInfo,
        getGuidePage,
        searchHandler,
        getCategoriesMappingPage,
      }}
    >
      {props.children}
    </APIContext.Provider>
  );
};

export { APIContextProvider };
