import React, { useContext } from "react";
import SearchPart from "../components/Home/SearchPart";
import LastReviewedPart from "../components/Home/LastReviewedPart";
import { COLORS } from "../helpers/Colors";

import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button } from "@material-ui/core";
import { arabic, english } from "../helpers/Translations";
import { LanguageContext } from "../contexts/LanguageContext";
import { Event } from "../tracking";

const useStyles = makeStyles((theme) => ({
  contributeBtn: {
    backgroundColor: COLORS.shamseyaBlue,
    color: COLORS.white,
    width: "250px",
    height: "50px",
    fontFamily: "Frutiger-Normal",
    borderRadius: "20px",
    textTransform: "none",
    marginTop: "10px",
    padding: "0px",
    fontSize: "18px",
    "&:hover": {
      backgroundColor: COLORS.hoverBlue,
    },
  },
  navlink: {
    textDecoration: "none",
    lineHeight: "48px",
    color: "inherit",
    height: "48px",
    margin: "0px",
    borderRadius: "20px",
  },
}));

const Home = () => {
  const classes = useStyles();

  const { currLanguage } = useContext(LanguageContext);
  let translations = currLanguage === "ar" ? arabic : english;
  return (
    <div>
      <SearchPart />
      <Grid
        container
        justify="center"
        alignItems="center"
        style={{ height: "100px", background: COLORS.whiteSmoke }}
      >
        <Button
          variant="contained"
          className={classes.contributeBtn}
          onClick={() =>
            Event("Contribution", "Contribution button clicked", "HOME_PAGE")
          }
        >
          <a
            href="https://ee.kobotoolbox.org/t33Vj78o"
            title="Contribution form"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.navlink}
          >
            {translations.statics["addAReview"]}
          </a>
        </Button>
      </Grid>
      <LastReviewedPart />
    </div>
  );
};

export default Home;
