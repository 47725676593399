import React, { createContext, useState } from "react";
export const LanguageContext = createContext();

const LanguageContextProvider = (props) => {
  const [currLanguage, setCurrLanguage] = useState("ar");

  return (
    <LanguageContext.Provider
      value={{
        currLanguage,
        setCurrLanguage,
      }}
    >
      {props.children}
    </LanguageContext.Provider>
  );
};

export { LanguageContextProvider };
