import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button } from "@material-ui/core";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import { COLORS } from "../../helpers/Colors";
import Search from "@material-ui/icons/Search";
import Filters from "./Filters";
import { SearchContext } from "../../contexts/SearchContext";
import { ScreenSizeContext } from "../../contexts/ScreenSizeContext";
import FiltersForMobile from "./FiltersForMobile";
import { arabic, english } from "../../helpers/Translations";
import { LanguageContext } from "../../contexts/LanguageContext";

const useStyles = makeStyles((theme) => ({
  background: {
    minHeight: "100vh",
    backgroundImage: `url(${require("../../assets/BG.jpg")})`,
    backgroundSize: "cover",
    marginTop: "-90px",
    width: "100%",
  },
  body: {
    direction: "rtl",
    marginTop: "17vh",
    textAlign: "center",
    maxWidth: "920px",
  },

  searchBtn: {
    backgroundColor: COLORS.shamseyaBlue,
    color: COLORS.white,
    width: "130px",
    height: "60px",
    fontFamily: "Frutiger-Normal",
    borderRadius: "20px",
    textTransform: "none",
    fontSize: "1.2rem",
    padding: "0px",
    "&:hover": {
      backgroundColor: COLORS.hoverBlue,
    },
  },
  navlink: {
    textDecoration: "none",
    lineHeight: "60px",
    color: "inherit",
    width: "130px",
    height: "60px",
    margin: "0px",
    borderRadius: "20px",
  },
  searchInputField: {
    backgroundColor: "rgba(256,256,256,0.7)",
    borderRadius: "20px",
    width: "95%",
    height: "60px",
    fontSize: "20px",
    paddingLeft: "20px",
    direction: "ltr",
    fontFamily: "Frutiger-Normal",
  },
  iconBtn: {
    backgroundColor: COLORS.shamseyaBlue,
    width: "70px",
    height: "70px",
    marginRight: "-5px",
    "&:disabled": {
      backgroundColor: COLORS.shamseyaBlue,
      width: "70px",
      height: "70px",
    },
  },
  searchIcon: {
    color: COLORS.white,
    width: "35px",
    height: "35px",
  },
}));
const SearchPart = () => {
  const classes = useStyles();

  const { searchInput, setSearchInput } = useContext(SearchContext);
  const { matchesMD, matchesSM } = useContext(ScreenSizeContext);
  const { currLanguage } = useContext(LanguageContext);

  let translations = currLanguage === "ar" ? arabic : english;

  let st = {};
  matchesMD === true ? (st.marginTop = "0px") : (st.marginTop = "10px");

  return (
    <Grid
      container
      justify="center"
      className={classes.background}
      style={{ backgroundPosition: matchesSM ? "0px" : "-300px" }}
    >
      <Grid
        item
        xs={12}
        s={12}
        md={12}
        lg={9}
        xl={9}
        container
        justify="center"
        alignItems="center"
        className={classes.body}
      >
        <Grid
          container
          direction={currLanguage === "ar" ? "row" : "row-reverse"}
          justify="space-between"
          item
          xs={12}
          style={{ marginBottom: "-30px", marginTop: "20px" }}
        >
          <Grid item xs={12} md={10} lg={10}>
            <Input
              className={classes.searchInputField}
              inputProps={{
                style: { textAlign: currLanguage === "ar" ? "end" : "start" },
              }}
              disableUnderline={true}
              type={"text"}
              placeholder={translations.statics.search}
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton disabled className={classes.iconBtn}>
                    <Search className={classes.searchIcon} />
                  </IconButton>
                </InputAdornment>
              }
            />
          </Grid>
          <Grid
            item
            container
            xs={12}
            md={2}
            lg={2}
            justify={matchesMD ? "flex-start" : "center"}
          >
            <Button
              style={st}
              variant="contained"
              className={classes.searchBtn}
            >
              <NavLink to="/search" className={classes.navlink}>
                {translations.statics.search}
              </NavLink>
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        container
        justify="center"
        style={{
          overflowX: "auto",
          textAlign: "center",
          height: "370px",
          overflowY: "hidden",
          margin: "20px",
        }}
      >
        {matchesMD === true ? <Filters /> : <FiltersForMobile />}
      </Grid>
    </Grid>
  );
};

export default SearchPart;
