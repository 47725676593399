import React, { useContext, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { COLORS } from "../../helpers/Colors";
import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import Standard from "./Standard";
import { arabic, english } from "../../helpers/Translations";
import { LanguageContext } from "../../contexts/LanguageContext";
import { APIContext } from "../../contexts/APIContext";
import { ScreenSizeContext } from "../../contexts/ScreenSizeContext";
import Tick from "@material-ui/icons/CheckRounded";
import LoadingPage from "../LoadingPage";

const useStyles = makeStyles((theme) => ({
  body: {
    backgroundColor: COLORS.whiteSmoke,
    overflow: "auto",
    padding: "20px 0px 0px 0px",
    marginTop: "10px",
  },
  title: {
    color: COLORS.shamseyaBlue,
    fontSize: "22px",
    borderRadius: "30px",
    width: "290px",
    height: "60px",
    lineHeight: "60px",
    textAlign: "center",
    backgroundColor: COLORS.white,
    fontFamily: "Frutiger-Normal",
    fontWeight: "900",
    margin: "auto",
  },
  extraTitle: {
    color: COLORS.shamseyaBlue,
    fontSize: "22px",
    borderRadius: "30px",
    height: "60px",
    lineHeight: "60px",
    textAlign: "center",
    fontFamily: "Frutiger-Normal",
    fontWeight: "600",
  },
  tickTitle: {
    background: COLORS.neutralYellow,
    color: COLORS.white,
    padding: "10px",
    borderRadius: "50%",
    width: "25px",
    height: "25px",
    margin: "10px",
  },
  tickExtraItem: {
    color: COLORS.successGreen,
    fontSize: "1.1rem",
  },
  tickExtraItemMaybe: {
    fontSize: "1.1rem",
    color: COLORS.neutralYellow,
  },
  alignExtraItem: {
    textAlign: "start",
  },
}));

const CommAssStandards = ({ data }) => {
  const classes = useStyles();
  const { currLanguage } = useContext(LanguageContext);
  const { getCategoriesMappingPage, API_URL } = useContext(APIContext);

  const [loading, setLoading] = useState(null);

  const [standards, setStandards] = useState(null);

  const [mappings, setMappings] = useState(null);

  const { matchesSM } = useContext(ScreenSizeContext);

  let translations = currLanguage === "ar" ? arabic : english;

  let mask = require("../../assets/new icons/Category-personal protection.svg");
  let sanitizerHand = require("../../assets/new icons/Category-sanitizers.svg");
  let air = require("../../assets/new icons/Category-airation.svg");
  let socialDistancing = require("../../assets/new icons/Category-distancing.svg");
  let person = require("../../assets/new icons/Category-entry points.svg");
  let sanitizerSpray = require("../../assets/new icons/Category-cleanliness and hygiene.svg");

  const standardsMeta = [
    {
      name: "protection",
      image: mask,
      title: translations.statics.personal_protect,
    },
    {
      name: "sanitizers",
      image: sanitizerHand,
      title: translations.statics.sanitize,
    },
    { name: "aeration", image: air, title: translations.statics.air },
    {
      name: "distance",
      image: socialDistancing,
      title: translations.statics.busy,
    },
    { name: "entry", image: person, title: translations.statics.entrance_test },
    {
      name: "cleanliness",
      image: sanitizerSpray,
      title: translations.statics.cleanliness,
    },
  ];

  useEffect(() => {
    if (mappings === null) return;
    getStandards();
  }, [mappings]);

  useEffect(() => {
    setLoading(true);
    if (mappings && mappings[currLanguage]) {
      setLoading(false);
      return;
    }
    getCategoriesMappingPage().then((response) => {
      if (!response.content?.rendered) return;
      let mappingString = response.content.rendered.substring(
        5,
        response.content.rendered.length - 5
      );
      mappingString.replace("<br>", "");
      let currMappings = {};
      mappingString.split(",").forEach((pair) => {
        if (pair.includes(":")) {
          currMappings[pair.split(":")[0].trim()] = pair.split(":")[1].trim();
        }
      });
      setMappings({ ...mappings, [currLanguage]: currMappings });
      setLoading(false);
    });
  }, [API_URL]);

  const getStandards = () => {
    let standards = {
      cleanliness: {
        attributesNo: [],
        attributesYes: [],
        attributesMaybe: [],
      },
      entry: { attributesNo: [], attributesYes: [], attributesMaybe: [] },
      distance: { attributesNo: [], attributesYes: [], attributesMaybe: [] },
      aeration: { attributesNo: [], attributesYes: [], attributesMaybe: [] },
      sanitizers: {
        attributesNo: [],
        attributesYes: [],
        attributesMaybe: [],
      },
      protection: {
        attributesNo: [],
        attributesYes: [],
        attributesMaybe: [],
      },
      extra: { attributesNo: [], attributesYes: [], attributesMaybe: [] },
    };
    Object.keys(data).forEach((key) => {
      if (key.length !== 3) return;

      // Cleanliness
      if (key[1].toString() === "1") {
        if (data[key] === "احياناً") {
          standards.cleanliness.attributesMaybe.push(key);
        } else if (data[key] === "نعم") {
          standards.cleanliness.attributesYes.push(key);
        } else if (data[key] === "لأ" || data[key] === "لا") {
          standards.cleanliness.attributesNo.push(key);
        }
      }

      // Entry
      else if (key[1].toString() === "2") {
        if (data[key] === "احياناً") {
          standards.entry.attributesMaybe.push(key);
        } else if (data[key] === "نعم") {
          standards.entry.attributesYes.push(key);
        } else if (data[key] === "لأ" || data[key] === "لا") {
          standards.entry.attributesNo.push(key);
        }
      }

      // Distance
      else if (key[1].toString() === "3") {
        if (data[key] === "احياناً") {
          standards.distance.attributesMaybe.push(key);
        } else if (data[key] === "نعم") {
          standards.distance.attributesYes.push(key);
        } else if (data[key] === "لأ" || data[key] === "لا") {
          standards.distance.attributesNo.push(key);
        }
      }

      // Aeration
      else if (key[1].toString() === "4") {
        if (data[key] === "احياناً") {
          standards.aeration.attributesMaybe.push(key);
        } else if (data[key] === "نعم") {
          standards.aeration.attributesYes.push(key);
        } else if (data[key] === "لأ" || data[key] === "لا") {
          standards.aeration.attributesNo.push(key);
        }
      }

      // Sanitizers
      else if (key[1].toString() === "5") {
        if (data[key] === "احياناً") {
          standards.sanitizers.attributesMaybe.push(key);
        } else if (data[key] === "نعم") {
          standards.sanitizers.attributesYes.push(key);
        } else if (data[key] === "لأ" || data[key] === "لا") {
          standards.sanitizers.attributesNo.push(key);
        }
      }

      // Protection
      else if (key[1].toString() === "6") {
        if (data[key] === "احياناً") {
          standards.protection.attributesMaybe.push(key);
        } else if (data[key] === "نعم") {
          standards.protection.attributesYes.push(key);
        } else if (data[key] === "لأ" || data[key] === "لا") {
          standards.protection.attributesNo.push(key);
        }
      }

      // Extra
      else if (key[1].toString() === "7") {
        if (data[key] === "احياناً") {
          standards.extra.attributesMaybe.push(key);
        } else if (data[key] === "نعم") {
          standards.extra.attributesYes.push(key);
        } else if (data[key] === "لأ" || data[key] === "لا") {
          standards.extra.attributesNo.push(key);
        }
      }
    });
    setStandards(standards);
  };

  return (
    <div className={classes.body} style={{ direction: "rtl" }}>
      {loading ? (
        <LoadingPage />
      ) : (
        <Grid item xs={10} style={{ margin: "auto" }}>
          <div
            style={{
              direction: currLanguage === "ar" ? "rtl" : "ltr",
              width: "100%",
            }}
          >
            <h2
              className={classes.title}
              style={{
                fontSize: currLanguage === "ar" ? "22px" : "16px",
              }}
            >
              {translations.statics.infContStand}
            </h2>
          </div>
          {standards === null ? null : (
            <Grid container justify="space-between" alignItems="flex-start">
              {standardsMeta.map((standardMeta) => (
                <Standard
                  key={standardMeta.name}
                  mappings={mappings[currLanguage]}
                  image={standardMeta.image}
                  attributesYes={standards[standardMeta.name].attributesYes}
                  attributesNo={standards[standardMeta.name].attributesNo}
                  attributesMaybe={standards[standardMeta.name].attributesMaybe}
                  title={standardMeta.title}
                />
              ))}
            </Grid>
          )}
          {mappings &&
            mappings[currLanguage] &&
            (standards?.extra?.attributesYes.length > 0 ||
              standards?.extra?.attributesMaybe.length > 0) && (
              <Grid
                style={{ direction: currLanguage === "ar" ? "rtl" : "ltr" }}
              >
                <Grid
                  container
                  alignItems="center"
                  justify={
                    !matchesSM
                      ? "center"
                      : currLanguage === "en"
                      ? "flex-end"
                      : "flex-start"
                  }
                  style={{
                    flexWrap: "nowrap",
                    direction: "rtl",
                    marginRight: currLanguage === "en" ? "auto" : "0",
                  }}
                >
                  <h2
                    className={classes.extraTitle}
                    style={{
                      fontSize: currLanguage === "ar" ? "22px" : "16px",
                    }}
                  >
                    {translations.statics.extraProcedures}
                  </h2>
                  <Tick className={classes.tickTitle} />
                </Grid>
                <List className={classes.root}>
                  {standards["extra"].attributesYes.map((standard) => (
                    <ListItem key={standard} className={classes.alignExtraItem}>
                      <ListItemIcon>
                        <Tick className={classes.tickExtraItem} />
                      </ListItemIcon>
                      <ListItemText
                        disableTypography={true}
                        primary={mappings[currLanguage][standard]}
                      />
                    </ListItem>
                  ))}
                  {standards["extra"].attributesMaybe.map((standard) => (
                    <ListItem key={standard} className={classes.alignExtraItem}>
                      <ListItemIcon>
                        <Tick className={classes.tickExtraItemMaybe} />
                      </ListItemIcon>
                      <ListItemText
                        disableTypography={true}
                        primary={`${mappings[currLanguage][standard]} ${
                          currLanguage === "ar" ? "أحياناً" : "sometimes"
                        }`}
                      />
                    </ListItem>
                  ))}
                </List>
              </Grid>
            )}
        </Grid>
      )}
    </div>
  );
};

export default CommAssStandards;
