export const COLORS = {
  white: "#fff",
  black: "#000",

  shamseyaBlue: "#1b60a1",

  hoverBlue: "#7495b5",
  disabledBlue: "#425261",

  // Fail and Success
  failRed: "#b22222",
  successGreen: "#14b814",

  neutralYellow: "#ffd700",

  footerBg: "#969696",

  whiteSmoke: "#f2efef",
  whiteWhisper: "#EDEAEA77",
  whiteWhisperer: "#EDEAEA55",

  gray: "#747474",
  disabledGray: "#A9A9A9",
  lightGray: "#e0e0e0"
};
