import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";

import guide from "../../assets/icons/guide.svg";
import search from "../../assets/icons/search.svg";

import LabeledIcon from "./LabeledIcon";
import ToggleIcon from "./ToggleIcon";
import { LanguageContext } from "../../contexts/LanguageContext";
import { arabic, english } from "../../helpers/Translations";
import { NavLink } from "react-router-dom";

const useStyles = makeStyles(() => ({
  navigation: {
    display: "inline-flex",
    alignItems: "center",
    height: "70px",
    marginRight: "25px",
  },
  flag: {
    width: "100%",
    height: "auto",
  },
  link: {
    width: "40px",
    height: "40px",
    margin: "0px 10px",
    marginTop: "10px",
  },
}));
const Navigation = () => {
  const classes = useStyles();
  const { currLanguage, setCurrLanguage } = useContext(LanguageContext);
  let translations = currLanguage === "ar" ? arabic : english;

  return (
    <div className={classes.navigation}>
      {/* Language */}
      <ToggleIcon setItem={setCurrLanguage} currItem={currLanguage} />
      <a
        className={classes.link}
        href="https://tunisie.salametna.com/"
        title={translations.statics["Salametna Tunsisia"]}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          alt={translations.statics["Salametna Tunsisia"]}
          className={classes.flag}
          src={require("../../assets/tunisia_flag.svg")}
        />
      </a>

      <NavLink to="/" className={classes.link}>
        <img
          alt={translations.statics["Salametna Egypt"]}
          className={classes.flag}
          src={require("../../assets/egypt_flag.png")}
        />
      </NavLink>
      {/* Guide */}
      <LabeledIcon
        icon={guide}
        label={translations.statics.guide}
        to="/guide"
      />
      {/* Search */}
      <LabeledIcon
        icon={search}
        label={translations.statics.search}
        to="/search"
      />
    </div>
  );
};

export default Navigation;
