import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";

import burger_icon from "../../assets/icons/burger_icon.png";
import { LanguageContext } from "../../contexts/LanguageContext";
import { arabic, english } from "../../helpers/Translations";
import IconMenu from "./IconMenu";
import ToggleIcon from "./ToggleIcon";
import { NavLink } from "react-router-dom";

const useStyles = makeStyles(() => ({
  navigation: {
    display: "inline-flex",
    alignItems: "center",
    height: "70px",
  },
}));
const MobNavigation = () => {
  const classes = useStyles();
  const { currLanguage, setCurrLanguage } = useContext(LanguageContext);
  let translations = currLanguage === "ar" ? arabic : english;

  const menuLabels = [
    { name: translations.statics.guide, to: "/guide" },
    { name: translations.statics.search, to: "/search" },
    { name: translations.statics["Salametna Egypt"], to: "/" },
    {
      name: translations.statics["Salametna Tunisia"],
      to: "https://tunisie.salametna.com/",
      outside: true,
    },
  ];

  return (
    <div className={classes.navigation}>
      {/* Language */}
      <ToggleIcon setItem={setCurrLanguage} currItem={currLanguage} />
      <IconMenu items={menuLabels} icon={burger_icon} />
    </div>
  );
};

export default MobNavigation;
