export const english = {
  translations: {
    محافظة: "governorate",

    // Tags - Place Types
    "مكان خدمات": "Service Area",
    "سوبر ماركت": "Supermarket",
    عيادة: "Clinic",
    صيدلية: "Pharmacy",
    بنك: "Bank",
    "مركز طبي": "Medical Center",
    مطبعة: "Print Shop",
    مخبز: "Bakery",
    "عيادة خاصة": "Private Clinic",
    عطارة: "Spice Shop",
    "مركز تجاري": "Mall",
    محل: "Store",
    "مركز خدمات": "Service Center",
    "محل حلاقة": "Barber Shop",
    مكتبة: "Stationary",
    مطعم: "Restaurant",
    كافيه: "Cafe",
    سنترال: "Wire Center",
    "مكان عمل": "Workplace",

    // Governorate
    القاهرة: "Cairo",
    الجيزة: "Giza",
    الإسكندرية: "Alexandria",
    القليوبية: "El-Qalyubia",
    الغربية: "El-Gharbeya",
    "البحر الأحمر": "Red Sea",
    السويس: "El-Swais",
    مطروح: "Matrouh",

    // Areas
    "التجمع الخامس": "Fifth Settlement",
    "مدينة الشروق": "Shorouk City",
    "مصر الجديدة": "Masr El-Gedida",
    الزمالك: "EL-Zamalek",
    المعادي: "El-Maadi",
    شبرا: "Shobra",
    العبور: "El-Obour",
    العباسية: "El-Abasseya",
    "عين شمس": "Ein Shams",
    القطامية: "El-Kattameya",
    المقطم: "El-Mokkattam",
    الرحاب: "El-Rehab",
    "وسط البلد": "Downtown",
    الأزهر: "El-Azhar",
    "السيدة زينب": "El-Sayeda Zeinab",
    "جاردن سيتي": "Garden City",
    "جسر السويس": "Gesr El-Suez",
    هليوبوليس: "Helipolis",
    حلوان: "Helwan",
    امبابة: "Embaba",
    مدينتي: "Madinaty",
    المنيل: "El-Manial",
    "مساكن الشيراتون": "Masaken Sheraton",
    "مصر القديمة": "Masr El-Kadima",
    "مدينة نصر": "Nasr City",
    "النزهة الجديدة": "El-Nozha El-Gedida",
    "القاهرة الجديدة": "New Cairo",
    "ابو رواش": "Abo Rawash",
    "شيخ زايد": "Sheikh Zayed",
    مهندسيين: "El-Mohandesin",
    هرم: "El-Haram",
    فيصل: "Faisal",
    دقي: "Dokki",
    العجوزة: "El-Agouza",
    "6 اكتوبر": "6th of October",
    جيزة: "Giza",
    المنتزة: "El-Montazah",
    "سيدي بشر قبلي": "Sidi Beshr Kebly",
    "سيدي جابر": "sidi Gaber",
    الرمل: "El-Raml",
    جليم: "Gliim",
    "شبرا الخيمة": "Shobra El-Khema",
    طنطا: "Tanta",
    // Side nav in search results page
    "نوع المكان": "Type of place",
    المحافظة: "Governorate",
    المنطقة: "Area",

    "مطاعم وقهاوي": "Restaurants and Cafes",
    "المحلات والمولات والصيدليات والمخابز": "Shops and malls",
    البنوك: "Banks",
    "مصلحة حكومية": "Governmental Institute",
    فنادق: "Hotels",
    حضانات: "Nurseries",
    "العيادات الخارجيه والتجميليه": "Clinics",
  },
  statics: {
    "Salametna Tunisia": "Salametna Tunisia",
    "Salametna Egypt": "Salametna Egypt",
    addAReview: "Add a review",
    ///// place
    // Top part
    scoreBoardTop: "This place achieved",
    scoreBoardBottom: "of the Infection Control Standards",
    googleMaps: "Google Maps Directions",
    participation: " visitor(s) contributed in this review",
    lastReview: "Last review was made on: ",
    gov: "governorate",

    // Standards
    infContStand: "Infection Control Standards",
    temp: "Body temperature measured before entering",
    nodoors: "No need to touch doorknobs to enter",
    airation: "Good aeration",
    crowd: "Place is not crowded",
    surfaces: "Clean and regularly sanitized surfaces",
    sanitizers: "Soap and Sanitizers available and reachable",
    workers: "Employees following Infection Control Standards",
    extraStandards: "Extra Standards",

    // New Standards
    cleanliness: "Cleanlines",
    entrance_test: "Entry",
    busy: "Social Distancing",
    air: "Aeration",
    sanitize: "Sanitizers",
    personal_protect: "Pesonal Protection",

    // Review Statement
    reviewStatement:
      "This review is based on the measures and standards set by Shamseya for Innovative Community Healthcare" +
      "Solutions based on a research covering international standards and user expectations. The published results are" +
      " based on the reviewers' personal experience and points of view at the time of review." +
      " The reviewed facility is allowed to ask for a re-evaluation in case the results do not match the actual condition" +
      ".",
    viewMore: "Click here to view more",
    extraProcedures: "Extra Standards",

    /////// Header
    guide: "Guide",
    search: "Search",
    lang: "Languages",

    egHospitalsStatement: " for Hospital reviews ",
    /////// Search Results Page
    noSearchResults:
      "No results were found, Please try another search words or other filters",
    searchResults: "Search Results",

    ///// Home
    showMore: "Show more",
    go: "Go",
    lastReviewed: "Recently Reviewed Places",

    /// CoronaXRamad
    download: "Download",
    page: "page",
    of: "of",
  },
};

export const arabic = {
  translations: {
    // Types
    "سوبر ماركت": "سوبر ماركت",
    "مكان خدمات": "مكان خدمات",
    عيادة: "عيادة",
    صيدلية: "صيدلية",
    بنك: "بنك",
    "مركز طبي": "مركز طبي",
    مطبعة: "مطبعة",
    مخبز: "مخبز",
    "عيادة خاصة": "عيادة خاصة",
    عطارة: "عطارة",
    "مركز تجاري": "مركز تجاري",
    محل: "محل",
    "مركز خدمات": "مركز خدمات",
    "محل حلاقة": "محل حلاقة",
    مكتبة: "مكتبة",
    مطعم: "مطعم",
    كافيه: "كافيه",
    سنترال: "سنترال",
    "مكان عمل": "مكان عمل",

    // gov
    القاهرة: "القاهرة",
    الجيزة: " الجيزة",
    الإسكندرية: "الإسكندرية",
    القليوبية: "القليوبية",
    الغربية: "الغربية",
    "البحر الأحمر": "البحر الأحمر",

    // areas
    "التجمع الخامس": "التجمع الخامس",
    "مدينة الشروق": "مدينة الشروق",
    "مصر الجديدة": "مصر الجديدة",
    الزمالك: "الزمالك",
    المعادي: "المعادي",
    شبرا: "شبرا",
    العبور: "العبور",
    العباسية: "العباسية",
    "عين شمس": "عين شمس",
    القطامية: "القطامية",
    المقطم: "المقطم",
    الرحاب: "الرحاب",
    "وسط البلد": "وسط البلد",
    الأزهر: "الأزهر",
    "السيدة زينب": "السيدة زينب",
    "جاردن سيتي": "جاردن سيتي",
    "جسر السويس": "جسر السويس",
    هليوبوليس: "هليوبوليس",
    حلوان: "حلوان",
    امبابة: "امبابة",
    مدينتي: "مدينتي",
    المنيل: "المنيل",
    "مساكن الشيراتون": "مساكن الشيراتون",
    "مصر القديمة": " مصر القديمة",
    "مدينة نصر": "مدينة نصر",
    "النزهة الجديدة": "النزهة الجديدة",
    "القاهرة الجديدة": "القاهرة الجديدة",
    "ابو رواش": "ابو رواش",
    "شيخ زايد": "شيخ زايد",
    مهندسيين: "مهندسيين",
    هرم: "هرم",
    فيصل: "فيصل",
    دقي: "دقي",
    العجوزة: "العجوزة",
    "6 اكتوبر": "6 اكتوبر",
    جيزة: "جيزة",
    المنتزة: "المنتزة",
    "سيدي بشر قبلي": "سيدي بشر قبلي",
    "سيدي جابر": "سيدي جابر",
    الرمل: "الرمل",
    جليم: "جليم",
    "شبرا الخيمة": "شبرا الخيمة",
    طنطا: "طنطا",

    ///// Side nav in search results
    "نوع المكان": "نوع المكان",
    المحافظة: "المحافظة",
    المنطقة: "المنطقة",
  },
  statics: {
    "Salametna Tunisia": "سلامتنا تونس",
    "Salametna Egypt": "سلامتنا مصر ",

    addAReview: "شارك بتقييم مكان",
    ///// place
    scoreBoardTop: "المكان حقق",
    scoreBoardBottom: "من معايير مكافحة العدوى",
    googleMaps: "الاتجاهات علي Google Maps",
    participation: " زوار قام بالمشاركة في هذا التقييم ",

    // standards
    infContStand: "معايير مكافحة العدوى",
    lastReview: "اخر مراجعة لتقييمات الزائرين: ",
    temp: "يتم قياس حرارة الزائرين قبل الدخول",
    nodoors: "يمكن دخول المكان دون لمس أبواب أو مقابض",
    airation: "التهوية جيدة",
    crowd: "لا يوجد تكدس أو إزدحام",
    surfaces: "الأسطح نظيفة ويتم تعقيمها بإستمرار",
    sanitizers: "الصابون ومعقم اليدين متوفرين ويسهل الوصول إليهم",
    workers: "العاملون ملتزمون باجراءات مكافحة العدوى ",
    reviewStatement:
      "هذا التقييم تم بناءًا على مؤشرات حددها فريق مؤسسة شمسية لإدارة الأنظمة الصحية وفقًا لبحث شمل الاجراءات الدولية وتطلعات مستخدمي الخدمات العامة. النتائج المعلنة تعبر عن وجهة نظر المقيمين وقت تقييمهم للأماكن المختلفة وبناءًا على تجاربهم الشخصية. يحق لإدارة الأماكن المختلفة طلب تقييم جديد في حالة اختلاف النتائج عن الواقع",
    viewMore: "إضغط هنا لمعرفة المزيد",

    // New Standards
    cleanliness: "التنظيف والتطهير ",
    entrance_test: "التقييم عند الدخول",
    busy: "التكدس والمساحات",
    air: "التهوية",
    sanitize: "المطهرات",
    personal_protect: "الوقاية الشخصية",
    extraProcedures: "الاجرائات الإضافية",

    //////// Search Results page
    //////// Navigations
    guide: "دليلك",
    search: "ابحث",
    lang: "اللغات",
    coronaRamdan: "رمضانXكورونا",

    egHospitalsStatement: " لتقييم المستشفيات ",
    gov: "محافظة",
    noSearchResults:
      "لا يوجد نتائج لهذا البحث، جرب كلمة بحث أخرى أو تصفيات أخرى",
    searchResults: "نتائج البحث",
    ///// Home
    showMore: "المزيد",
    go: "اذهب",
    lastReviewed: "آخر ما تم تقييمه",

    // CoronaXRamada
    download: "تحميل الملف",
    page: "صفحة",
    of: "من",
  },
};
