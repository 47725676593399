import React, { useState, useEffect, useContext } from "react";
import { Grid } from "@material-ui/core";
import { COLORS } from "../helpers/Colors";
import { makeStyles } from "@material-ui/core/styles";
import GoogleMapsPlugin from "../components/Place/GoogleMapsPlugin";
import CommAssStandards from "../components/Place/CommAssStandardsPart";
import { NavLink } from "react-router-dom";
import LoadingPage from "../components/LoadingPage";
import NotFoundPage from "./NotFoundPage";
import MuiAlert from "@material-ui/lab/Alert";
import { APIContext } from "../contexts/APIContext";
import { LanguageContext } from "../contexts/LanguageContext";
import { arabic, english } from "../helpers/Translations";
import PlaceName from "../components/Place/PlaceName";
import { ScreenSizeContext } from "../contexts/ScreenSizeContext";

const useStyles = makeStyles((theme) => ({
  topPart: {
    direction: "rtl",
    marginTop: "50px",
  },
  image: {
    height: "240px",
    marginTop: "10px",
    borderRadius: "20px",
    width: "100%",
    maxWidth: "400px",
  },

  clickHere: {
    margin: "5px",
    color: COLORS.shamseyaBlue,
  },
}));

const Alert = (props) => {
  return (
    <MuiAlert
      style={{ direction: "ltr", width: "80%", color: COLORS.failRed }}
      elevation={6}
      variant="filled"
      {...props}
    />
  );
};

const Place = (props) => {
  const classes = useStyles();

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState({ isError: false, invalidId: false });

  const { matchesMD } = useContext(ScreenSizeContext);

  const { getPlaceInfo } = useContext(APIContext);
  const { currLanguage } = useContext(LanguageContext);

  let translations = currLanguage === "ar" ? arabic : english;

  useEffect(() => {
    let pathname = props.history.location.pathname;
    const id = pathname.split("/")[2].split("/")[0];

    getPlaceInfo(id)
      .then((data) => {
        setData(data.acf);
        setError({ isError: false });
        setLoading(false);
      })
      .catch((error) => {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.code
        ) {
          if (error.response.data.code === "rest_post_invalid_id") {
            setError({
              isError: true,
              errorMessage: error.response.data.message
                ? error.response.data.message
                : "Network Error",
              invalidID: true,
            });
          }
        } else {
          setError({
            isError: true,
            errorMessage: "Network Error",
            invalidID: false,
          });
        }
      });
  }, [props]);

  return (
    <div>
      {error.isError && error.invalidID ? (
        <NotFoundPage />
      ) : error.isError ? (
        <Alert severity="error">{error.errorMessage}</Alert>
      ) : loading ? (
        <LoadingPage />
      ) : (
        <div>
          <Grid
            container
            item
            xs={11}
            sm={11}
            md={11}
            lg={9}
            xl={6}
            justify="space-between"
            alignItems="center"
            className={classes.topPart}
            style={{
              margin: "auto",
              minHeight: "250px",
            }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
              container
              justify="center"
              alignItems={!matchesMD ? "center" : "flex-start"}
              direction="column"
              style={{ height: "100%" }}
            >
              <PlaceName data={data} />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              lg={3}
              xl={3}
              container
              alignItems="center"
              justify="center"
            >
              {data.image && (
                <img
                  className={classes.image}
                  alt={data.name}
                  src={
                    data.image?.url
                      ? data.image.url
                      : require("../assets/favicon.png")
                  }
                />
              )}
            </Grid>
            {data["long"] && data["lat"] && (
              <Grid
                item
                xs={12}
                s={12}
                md={4}
                lg={4}
                xl={4}
                container
                alignItems="center"
                justify="center"
              >
                <GoogleMapsPlugin
                  lat={data.lat}
                  long={data.long}
                  src={data["google_maps_embedding"]}
                  placeName={data[`place_name_${currLanguage}`]}
                />
              </Grid>
            )}
          </Grid>

          <CommAssStandards data={data} />

          <Grid container justify="center" style={{ background: "#f8f8f8" }}>
            <div
              style={{
                width: "80%",
                direction: "rtl",
                color: "#707070",
                fontSize: "16px",
                textAlign: "center",
                lineHeight: "2",
              }}
            >
              <p style={{ marginBottom: "0px" }}>
                {translations.statics.reviewStatement}
              </p>
              <NavLink className={classes.clickHere} to="/guide">
                {translations.statics.viewMore}
              </NavLink>
            </div>
          </Grid>
        </div>
      )}
    </div>
  );
};

export default Place;
