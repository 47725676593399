import React, { useEffect, useState, useContext } from "react";
import { Grid } from "@material-ui/core";
import { COLORS } from "../helpers/Colors";
import { APIContext } from "../contexts/APIContext";

const Guide = () => {
  const [content, setContent] = useState({});
  const [loading, setLoading] = useState(true);

  const { getGuidePage } = useContext(APIContext);

  useEffect(() => {
    getGuidePage().then((response) => {
      setContent(response);
      setLoading(false);
    });
  }, []);

  const getContentHTML = () => (
    <Grid container justify="center">
      <Grid item xs={11} style={{ minHeight: "100%" }}>
        <p
          style={{
            textAlign: "center",
            color: COLORS.shamseyaBlue,
            fontFamily: "Frutiger-Normal",
            fontWeight: "900",
            fontSize: "20px",
          }}
        >
          {content.title.rendered}
        </p>
        <Grid
          dangerouslySetInnerHTML={{
            __html: content.acf["main_content"],
          }}
        ></Grid>
      </Grid>
    </Grid>
  );

  return <Grid>{loading ? null : getContentHTML()}</Grid>;
};

export default Guide;
