import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { arabic, english } from "../../helpers/Translations";
import { LanguageContext } from "../../contexts/LanguageContext";

const useStyles = makeStyles((theme) => ({
  socialNetworkIcon: {
    width: "100%",
    height: "100%",
  },
  link: {
    width: "50px",
    height: "50px",
  },
}));

const SocialMedia = () => {
  const classes = useStyles();
  let propsStyle = { justifyContent: "center" };
  propsStyle.width = "100%";
  propsStyle.direction = "rtl";
  propsStyle.display = "inline-flex";
  propsStyle.marginTop = "10px";

  const { currLanguage } = useContext(LanguageContext);
  let translations = currLanguage === "ar" ? arabic : english;

  return (
    <div style={propsStyle}>
      <p
        style={{
          marginLeft: "5px",
          fontSize: "18px",
          lineHeight: "15px",
          marginBottom: "5px",
        }}
      >
        {translations.statics.egHospitalsStatement}
      </p>
      <a
        className={classes.link}
        href="https://eghospitals.com/"
        title="eghospitals"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          alt="eghospitals.com"
          src={require("../../assets/egHospitalsH.png")}
          className={classes.socialNetworkIcon}
        />
      </a>
      <a
        className={classes.link}
        href="https://shamseya.org/"
        title="Shamseya"
        style={{ marginRight: "10px" }}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          alt="shamseya.org"
          src={require("../../assets/shamseya.svg")}
          className={classes.socialNetworkIcon}
        />
      </a>
    </div>
  );
};

export default SocialMedia;
