import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { COLORS } from "../../helpers/Colors";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { NavLink } from "react-router-dom";

const useStyles = makeStyles(() => ({
  navButton: {
    textAlign: "center",
    cursor: "pointer",
    height: "100%",
    margin: "10px",
    marginRight: "20px",
  },
  imageContainer: {
    width: "25px",
    height: "100%",
    margin: "auto",
    display: "inline-flex",
  },
  navIcon: {
    width: "25px",
    height: "25px",
    marginTop: "auto",
    marginBottom: "auto",
  },
  navLink: {
    textDecoration: "none",
    color: COLORS.black,
    margin: "5px",
    fontFamily: "Frutiger-Normal",
    fontSize: "14px",
    textAlign: "center",
  },
}));

const IconMenu = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.navButton}>
      <span onClick={handleClick}>
        <div className={classes.imageContainer}>
          <img src={props.icon} className={classes.navIcon} alt={props.label} />
        </div>
      </span>

      <Menu
        elevation={5}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleClose(null)}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {props.items.map((item) =>
          !item.outside ? (
            <MenuItem key={item.name} onClick={handleClose}>
              <NavLink className={classes.navLink} to={item.to}>
                {item.name}
              </NavLink>
            </MenuItem>
          ) : (
            <div className={classes.navLink}>
              <a
                className={classes.navLink}
                href={item.to}
                title={item.name}
                target="_blank"
                rel="noopener noreferrer"
              >
                {item.name}
              </a>
            </div>
          )
        )}
      </Menu>
    </div>
  );
};

export default IconMenu;
