import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { COLORS } from "../../helpers/Colors";
import { Button, ButtonGroup, Grid, Switch } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  button: {
    textTransform: "none",
  },
}));

const ToggleIcon = (props) => {
  const classes = useStyles();

  return (
    <ButtonGroup variant="text" aria-label="langiages button">
      <Button
        className={classes.button}
        style={
          props.currItem === "en"
            ? {
                fontWeight: "600",
                color: COLORS.shamseyaBlue,
              }
            : {}
        }
        onClick={() => props.setItem("en")}
      >
        En
      </Button>
      <Button
        className={classes.button}
        style={
          props.currItem === "ar"
            ? {
                fontWeight: "600",
                color: COLORS.shamseyaBlue,
              }
            : {}
        }
        onClick={() => props.setItem("ar")}
      >
        Ar
      </Button>
    </ButtonGroup>
  );
};

export default ToggleIcon;
